// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

.qpass-text-body-alt-normal {
  color: var(--Text-Text-Primary, #1f1f1f);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: var(--Type-Body-Family, Inter);
  font-size: var(--Type-LG, 18px);
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 21.6px */
}

.qpass-text-body-alt-slim {
  color: var(--Text-Text-Secondary, #535353);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: "dlig" on;
  font-family: var(--Type-Body-Family, Inter);
  font-size: var(--Type-MD, 16px);
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 22.4px */
}

.qpass-textarea {
  textarea {
    width: 100%;
    border: 1px solid var(--Borders-Border-Strong, #afbed8);
    height: 100px;
    border-radius: 5px;
  }
}
textarea:focus,
.qpass-textareal:focus {
  //border: 2px solid var(--Borders-Border-Strong, #afbed8) !important;
  //border-radius: 5px;
  outline: none;
  opacity: 1;
  border-radius: 8px;
}
 

 
.qpass-searchbar {
  background: var(--Backgrounds-BG-Sub-elevation, #F9FAFC);
  padding: 0px!important;
  
  min-height: 40px;
  margin-bottom: 10px;
  
  &:not(:focus-within) {
    .searchbar-search-icon {
      background-image: url('../assets/custom-icons/qpass-location_gray.svg');;
      background-size: contain;
      background-repeat: no-repeat;
      width: 24px; /* Adjust the size */
      height: 24px;
      top:auto;           /* makes the icon to vertical align */
      color: transparent; /* Hides any residual color from the default icon */
      /* Ensure no old background icon persists */
      mask: none; 
      -webkit-mask: none; 
    }
  }
  .searchbar-input-container {
    //height: 42px;
    display: flex;
    min-height: 44px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--Borders-Border-Strong, #AFBED8);
    --background: var(--Backgrounds-BG-Sub-elevation, #F9FAFC);
    padding-inline-start: 36px; 

    &:focus {
  //  box-shadow: var(--qpass-input-box-shadow);
      opacity: 1.0;
      border: 3px solid #2A71D8;
      border-radius: 4px;
      background: var(--Backgrounds-BG-Sub-elevation, #F9FAFC);
    }
 
    &:has(input:focus) {
      // Styles for the parent when child is focused (in modern browsers that support `:has`)
      border: 3px solid #2A71D8;
    }
  }
 
  input:not(:placeholder-shown) {
    border-top-left-radius: 0px ;
    border-bottom-left-radius: 0px;
    border-left: 1px solid var(--Backgrounds-BG-Base, #F1F5FC);
    overflow: hidden;
    color: var(--Text-Text-Primary, #1F1F1F);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;
    text-overflow: ellipsis;

    /* Body/Default */
    font-family: var(--Type-Body-Family, Inter);
    font-size: var(--Type-SM, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }

  input:placeholder-shown {
    border-top-left-radius: 0px ;
    border-bottom-left-radius: 0px;
    border-left: 1px solid var(--Backgrounds-BG-Base, #F1F5FC);
    padding-inline-start: 8px !important;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: var(--Text-Text-Primary, #1F1F1F);
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;
    text-overflow: ellipsis;
    top:auto;
    /* Body/Default */
    font-family: var(--Type-Body-Family, Inter);
    font-size: var(--Type-SM, 14px);
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 19.6px */
  }


}