.qpass-alert {
  --max-width: 90vw;
  --max-height: 90vh;
  --qpass-typo-title2-font-size: 20px;
  --ion-backdrop-color: var(--qpass-color-white);

  .alert-wrapper {
    box-shadow: var(--qpass-button-box-shadow);
  }

  .alert-title {
    text-align: start;
    padding-bottom: 0;
  }

  .alert-message {
    padding-top: 24px;
    padding-bottom: 24px;
    max-height: unset;
    text-align: start;
    font-size: var(--qpass-typo-body-font-size);
  }

  button {
    &:only-child {
      background: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast) !important;
    }
  }
}
