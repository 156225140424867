/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
// @import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// @import "@ionic/angular/css/normalize.css";
// @import "@ionic/angular/css/structure.css";
// @import "@ionic/angular/css/typography.css";
// @import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
// @import "@ionic/angular/css/padding.css";
// @import "@ionic/angular/css/float-elements.css";
// @import "@ionic/angular/css/text-alignment.css";
// @import "@ionic/angular/css/text-transformation.css";
// @import "@ionic/angular/css/flex-utils.css";

/**
 * Ionic Dark Palette
 * -----------------------------------------------------
 * For more information, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* @import '@ionic/angular/css/palettes/dark.always.css'; */
/* @import '@ionic/angular/css/palettes/dark.class.css'; */
/* @import '@ionic/angular/css/palettes/dark.system.css'; */

// for hiding the browser url bar (iphone)

@import "@q-pass/core/styles/2024-theme";
@import '~flag-icons/css/flag-icons.min.css';

// Maybe we can move it to qpass-npm-packages (_fonts.scss)
@import 'https://fonts.googleapis.com/css?family=Syne';

html.plt-mobileweb.ios {
  height: 101vh;
  max-height: none;
  overflow-y: scroll;
}

ion-modal.bottom-sheet-modal {
  --height: 56% !important;
  align-items: end;
}

ion-modal.bottom-sheet-small-modal {
  --height: 45% !important;
  align-items: end;
}

body.pwa {

  --pwa-main-margin: 40px;

  // // Hide scrollbar for Chrome, Safari and Opera
  // ion-content::part(scroll)::-webkit-scrollbar {
  //   display: none;
  // }

  // Hide scrollbar for IE, Edge and Firefox
  ion-content::part(scroll) {
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
  }

  //background: #E1E5EB;
  @media only screen and (min-width: 700px) {

    background: conic-gradient(from 180deg at 50% 50%, #EEF0FC 0deg, #F0FAFD 214.20001029968262deg, #CDE9FD 360deg);

    ion-modal {
      --width: 100%;
    }

  }


  .store-button {
    &.app-store {
      background: url('assets/images/btn-appstore-light.svg');
    }

    &.gplay-store {
      background: url('assets/images/btn-gplay-light.svg');
    }
  }

  &.dark {

    //background: #1C1D20;
    @media only screen and (min-width: 700px) {
      background: conic-gradient(from 180deg at 50% 50%, #082232 0deg, #152B30 136.79999828338623deg, #22203E 360deg);
    }

    .store-button {
      &.app-store {
        background: url('assets/images/btn-appstore-dark.svg');
      }

      &.gplay-store {
        background: url('assets/images/btn-gplay-dark.svg');
      }
    }

  }

  .store-button {

    &.app-store {
      width: 183px;
      height: 60px;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.gplay-store {
      width: 183px;
      height: 54px;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  .block-container-1 {
    display: flex;
    width: 200px;
    margin: auto;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    flex-shrink: 0;

    .logo-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 11.558px;

      .logo-container-inner {
        display: flex;
        align-items: center;
        gap: 13.484px;

        .logo-icon {
          width: 50px;
          height: 50px;
        }

        .logo-text {
          width: 119.432px;
          height: 27.417px;
        }
      }

      .slogan-text {
        color: var(--qpass-color-text-primary);
        text-align: center;
        font-variant-numeric: lining-nums proportional-nums;
        font-feature-settings: 'dlig' on;
        font-family: Syne;
        font-size: 19.336px;
        font-style: normal;
        font-weight: 600;
        line-height: 115%;
        /* 22.237px */
      }

    }

    .store-buttons-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
    }

    .qr-container {
      display: flex;
      width: 190px;
      height: 190px;
      padding: 10px;
      align-items: center;
      gap: 8px;

      .qr-code {
        flex: 1 0 0;
        align-self: stretch;
      }
    }

  }

  @media only screen and (max-width: 699px) {

    .left-container {
      display: none;
    }

    // ion-app {
    //   margin: 40px;
    //   right: 0;
    //   left: 0;
    //   min-width: 370px;
    //   overflow: hidden;
    //   border-radius: 20px;
    //   border-width: 4px;
    //   border-color: #C0D6F6;
    //   border-style: solid;
    // }


    .right-home-container {
      display: none;
    }

  }

  @media only screen and (min-width: 700px) and (max-width: 899px) {

    .left-container {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: calc(100% - 400px - 40px);

      .qr-container {
        display: none;
      }
    }

    .right-home-container {
      display: none;
    }

    ion-app {
      left: unset !important;
      right: 0 !important;
      width: 400px;
      overflow: hidden;
      margin-top: var(--pwa-main-margin);
      margin-right: var(--pwa-main-margin);
      margin-bottom: var(--pwa-main-margin);
      border-radius: 20px;
      border-width: 4px;
      border-color: #C0D6F6;
      border-style: solid;
    }

    .sliding-panel.position-1 {
      height: 120.5px;
      //height: calc(120.5px - (2 * var(--pwa-main-margin)));
    }

    .sliding-panel.position-2 {
      height: calc(69vh - (2 * var(--pwa-main-margin)));
      /* Second position: 0.69 of viewport height */
    }

    .sliding-panel.position-3 {
      height: calc(90dvh - (2 * var(--pwa-main-margin)));
    }

  }

  @media only screen and (min-width: 900px) {

    .left-container {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: calc((100% - 400px)/2);

      .store-buttons-container {
        display: none;
      }
    }

    .right-home-container {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      transform: translateY(-50%);
      width: calc((100% - 400px)/2);
      ;

      .block-container-2 {
        display: flex;
        padding: 40px 0px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        flex: 1 0 0;
      }
    }

    ion-app {
      margin: auto;
      top: 0;
      bottom: 0;
      width: 400px;
      overflow: hidden;
      margin-top: var(--pwa-main-margin);
      margin-bottom: var(--pwa-main-margin);
      border-radius: 20px;
      border-width: 4px;
      border-color: #C0D6F6;
      border-style: solid;
    }

    .sliding-panel.position-1 {
      height: 120.5px;
    }

    .sliding-panel.position-2 {
      height: calc(69vh - (2 * var(--pwa-main-margin)));
      /* Second position: 0.69 of viewport height */
    }

    .sliding-panel.position-3 {
      height: calc(90dvh - (2 * var(--pwa-main-margin)));
    }
  }

}


.info-modal {
  --width: 94%;
  --backdrop-opacity: 0.3;

  &--basket {
    --width: 100%;
    align-items: end;
  }

  &--top {
    padding-top: 64px;
    align-items: start;
  }

  &--height_120 {
    &::part(content) {
      max-height: 120px !important;
    }
  }

  &--height_140 {
    &::part(content) {
      max-height: 140px !important;
    }
  }

  &--height_180 {
    &::part(content) {
      max-height: 180px !important;
    }
  }

  &--height_220 {
    &::part(content) {
      max-height: 220px !important;
    }
  }

  &--height_240 {
    &::part(content) {
      max-height: 240px !important;
    }
  }

  &--height_250 {
    &::part(content) {
      max-height: 250px !important;
    }
  }

  &--height_380 {
    &::part(content) {
      max-height: 380px !important;
    }
  }

  &--height_480 {
    &::part(content) {
      max-height: 480px !important;
    }
  }

  &::part(content) {
    border-radius: 8px;
    background-color: var(--ion-background-color);
    overflow: hidden;
    max-height: 300px;
  }

  .point-right {
    background-color: var(--ion-background-color);
    // background-color: red;
    position: absolute;
    right: -14px;
    top: 5px;
    height: 28px;
    width: 28px;
    transform: rotate(45deg);

  }

  ion-content {
    .info-modal-header {
      margin: 24px 24px 12px 24px;
    }

    .info-modal-body {
      margin: 0 24px 24px 24px;

      ion-item.qpass-checkbox {
        --inner-padding-start: 0 !important;
        --inner-padding-end: 0 !important;
        --padding-start: 0;
      }

    }
  }

  ion-footer {
    ion-row {
      margin-bottom: 16px;
    }

    ion-row:last-of-type {
      margin-bottom: 0;
    }

  }

}

swiper-slide {
  .qpass-selectable {
    display: flex;
    height: 48px;
    padding: 6px 20px;
    align-items: center;
    gap: 8px;
    border-right: 1px solid var(--Borders-Border-Weak, #CFD5E0);
    background: var(--Backgrounds-BG-Base, #F1F5FC);

    ion-label {
      color: var(--Text-Text-Primary, #1F1F1F);
      font-variant-numeric: lining-nums proportional-nums;
      font-feature-settings: 'dlig' on;
      /* Headings/Body Title */
      font-family: var(--Type-Body-Family, Inter);
      font-size: var(--Type-SM, 14px);
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      /* 16.8px */
    }
  }

  .qpass-selected {
    background: var(--Backgrounds-BG-Elevated, #FFF);
  }
}

.swiper-slide-width-unset {
  width: unset !important;
}

.swiper-pagination-qpass {
  text-align: center;

  .swiper-pagination-bullet {
    margin: 0 4px !important;
    background: var(--ion-background-color) !important;
    box-shadow: var(--qpass-swiper-pagination-bullet-box-shadow) !important;
    opacity: 1 !important;
    width: 12px !important;
    height: 12px !important;
  }

  .swiper-pagination-bullet-active {
    margin: 0 4px !important;
    opacity: 1 !important;
    width: 12px !important;
    height: 12px !important;
    background: var(--qpass-background-linear-gradient) !important;
    border: var(--qpass-swiper-pagination-bullet-active-border) !important;
    box-shadow: var(--qpass-swiper-pagination-bullet-active-box-shadow) !important;
  }

}

.speed-order-modal {
  --width: 343px;
  --height: 387px;
}


.weakBorder .scrollable-container> :first-child {
  margin-left: 0px;
}

.scrollable-container {
  // This class needs to go to the global scss
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  scrollbar-width: thin;
  /* Firefox: Customizes scrollbar width */
}

.scrollable-container> :first-child {
  margin-left: 16px;
}

ion-label {
  white-space: normal !important;
}

@font-face {
  font-family: 'Digital Numbers Regular';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/DigitalNumbers-Regular.woff') format('woff');
}

.input-right-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  /* Adjust this value according to your preference */
  transform: translateY(-50%);
}

ion-datetime {
  ::ng-deep button.time-body {
    background-color: var(--ion-color-primary, #0054e9) !important;
    /* Ensure button background color */
    color: #000 !important;
    /* Set button text color to black */
  }
}

.plain_text {
  color: var(--Text-Text-Secondary, #535353);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  font-family: var(--Type-Body-Family, Inter);
  font-size: var(--Type-XS, 12px);
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 16.8px */
}

.qpass-radio {
  display: flex;
  padding: 4px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  ion-radio::part(container) {
    width: 24px;
    height: 24px;
    border-radius: 14px;
    border: 2px solid #ddd;
  }

  ion-radio.radio-checked::part(container) {
    background: var(--Accent-Brand, #4B6AA0);
    border-color: transparent;
    content: "\f156" !important;
  }

  ion-radio.radio-checked::part(mark) {
    width: 10px;
    height: 10px;
    border-width: 5px;
    border-radius: 10px;
    border-color: #fff;
  }
}

.qpass-checkbox {
  display: flex;
  padding: 4px 0px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  ion-checkbox {
    --size: 24px;
    --checkbox-background-checked: var(--Accent-Brand, #4B6AA0);
  }

  ion-checkbox::part(container) {
    border-radius: 6px;
    border-radius: 6px;
    border: 1px solid var(--Borders-Border-Strong, #AFBED8);
    //  background: var(--Backgrounds-BG-Elevated, #FFF);
  }
}


.group-block {

  display: flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background: var(--Backgrounds-BG-Elevated, #fff);

  .options {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;

    .option {
      display: flex;
      padding: 4px 0px;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      .txt {

        /* Body/Default */
        font-family: var(--Type-Body-Family, Inter);
        font-size: var(--Type-SM, 14px);
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 19.6px */
        font-feature-settings: "dlig" on;
        font-variant-numeric: lining-nums proportional-nums;
      }

      .extra {
        color: var(--Accent-Red, #d62f2f);
        font-weight: 700;
        flex: 1 0 0;
      }

      .lbl {
        color: var(--Text-Text-Primary, #1f1f1f);
      }

      .price {
        color: var(--Text-Text-Secondary, #535353);

      }
    }

  }
}

///////     Language Buttons -------------

.lang-text {
  color: var(--Text-Text-Primary, #1F1F1F);
  font-variant-numeric: lining-nums proportional-nums;
  font-feature-settings: 'dlig' on;
  /* Headings/Card Title */
  font-family: var(--Type-Body-Family, Inter);
  font-size: var(--Type-MD, 16px);
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 19.2px */
  text-transform: uppercase;
}

.popover-content:host {
  width: 160px;
}

.language-list {
  border-radius: 8px;
  border: 1px solid var(--Borders-Border-Weak, #CFD5E0);
  background: var(--Backgrounds-BG-Elevated, #FFF);
  box-shadow: 0px 54px 87px 0px rgba(0, 43, 80, 0.11), 0px 19.711px 31.756px 0px rgba(0, 43, 80, 0.08), 0px 9.569px 15.417px 0px rgba(0, 43, 80, 0.05), 0px 4.691px 7.558px 0px rgba(0, 43, 80, 0.02), 0px 1.855px 2.988px 0px rgba(0, 43, 80, 0.00);
  border-radius: 8px;
  border: 1px solid var(--Borders-Border-Weak, #CFD5E0);
  background: var(--Backgrounds-BG-Elevated, #FFF);
  width: 160px;
  position: fixed;
  right: 2px;
  z-index: 999;

  .selected {
    --background: var(--Buttons-Primary-Button-Primary-Fill-2, #415474);
    color: var(--Buttons-Primary-Button-Primary-Text, #FFF);
  }

  ion-item {
    display: flex;
    //  min-height: 48px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    --background: var(--Buttons-Primary-Button-secondary-Fill-1, #FFF);
    color: var(--Buttons-Secondary-Button-Secondary-Text, #000);
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-feature-settings: 'dlig' on;
    /* Headings/Card Title */
    font-family: var(--Type-Body-Family, Inter);
    font-size: var(--Type-MD, 16px);
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 19.2px */
  }
}

/// <<<<<<<<<<<<<<<<<<<<<<<<<<<

@import 'swiper/swiper-bundle.css';

@import './styles/index';